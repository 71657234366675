import { default as indexxjkD4FqntFMeta } from "/codebuild/output/src3037655557/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45inviteO1y6A9FmV2Meta } from "/codebuild/output/src3037655557/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchaseBZayeJuw8GMeta } from "/codebuild/output/src3037655557/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexiVXOvmSSkxMeta } from "/codebuild/output/src3037655557/src/pages/account/index.vue?macro=true";
import { default as settingsIPmaFixyhiMeta } from "/codebuild/output/src3037655557/src/pages/account/settings.vue?macro=true";
import { default as subscriptionYH0e74VTwbMeta } from "/codebuild/output/src3037655557/src/pages/account/subscription.vue?macro=true";
import { default as transactionsTHETOxMEajMeta } from "/codebuild/output/src3037655557/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptiongX5mykrkZPMeta } from "/codebuild/output/src3037655557/src/pages/account/update-subscription.vue?macro=true";
import { default as preview4hIvo0Ho9lMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeyMuAfyxw1dMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as index5LgjsloLL8Meta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emails1mhzvVOKU2Meta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editB3VT5TIoxnMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsaN55tDxBETMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as index5LlkPctD9aMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsuGwjm8lSURMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesbkbzFByYsCMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as gallery8R0zIpTGtkMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsuZzA6yNCVsMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as indexwBJFOQFm8eMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersk6ZNFKDckUMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editqhKd8lDvBbMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexXZPkgfahKuMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsaxbxapA6deMeta } from "/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93DhX6rwVIg0Meta } from "/codebuild/output/src3037655557/src/pages/donate/[slug].vue?macro=true";
import { default as indexDcMsYZbsoTMeta } from "/codebuild/output/src3037655557/src/pages/donate/index.vue?macro=true";
import { default as _91slug_93H55GUWoZdmMeta } from "/codebuild/output/src3037655557/src/pages/events/[slug].vue?macro=true";
import { default as indexDlVeKdFrVVMeta } from "/codebuild/output/src3037655557/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordsXbgYe3hIuMeta } from "/codebuild/output/src3037655557/src/pages/forgot-password.vue?macro=true";
import { default as filesJ54NII7RfyMeta } from "/codebuild/output/src3037655557/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallerybCq49zCF2AMeta } from "/codebuild/output/src3037655557/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexOC2OtNu84EMeta } from "/codebuild/output/src3037655557/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopTKYVZDT2uCMeta } from "/codebuild/output/src3037655557/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_935IC1wfX89yMeta } from "/codebuild/output/src3037655557/src/pages/groups/[slug].vue?macro=true";
import { default as indexYo2PUMFidNMeta } from "/codebuild/output/src3037655557/src/pages/groups/index.vue?macro=true";
import { default as indexaNQNontNXOMeta } from "/codebuild/output/src3037655557/src/pages/index.vue?macro=true";
import { default as joinFxNSmuCwSGMeta } from "/codebuild/output/src3037655557/src/pages/join.vue?macro=true";
import { default as loginuYS4gUdQ6oMeta } from "/codebuild/output/src3037655557/src/pages/login.vue?macro=true";
import { default as confirmationqhCQmfosHbMeta } from "/codebuild/output/src3037655557/src/pages/membership/confirmation.vue?macro=true";
import { default as index5jnAImKIUHMeta } from "/codebuild/output/src3037655557/src/pages/membership/index.vue?macro=true";
import { default as _91slug_936wOQGU6XljMeta } from "/codebuild/output/src3037655557/src/pages/news/[slug].vue?macro=true";
import { default as indexnpUYSToeRGMeta } from "/codebuild/output/src3037655557/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordYMoHkZGK6QMeta } from "/codebuild/output/src3037655557/src/pages/reset-password.vue?macro=true";
import { default as setup_452fasMLjzZudL0Meta } from "/codebuild/output/src3037655557/src/pages/setup-2fa.vue?macro=true";
import { default as shopVVLN4WV9O4Meta } from "/codebuild/output/src3037655557/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src3037655557/src/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src3037655557/src/pages/accept-invite.vue").then(m => m.default || m)
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchaseBZayeJuw8GMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/extra-purchase.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexiVXOvmSSkxMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsIPmaFixyhiMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionYH0e74VTwbMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsTHETOxMEajMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/transactions.vue").then(m => m.default || m)
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptiongX5mykrkZPMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/account/update-subscription.vue").then(m => m.default || m)
  },
  {
    name: emails1mhzvVOKU2Meta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emails1mhzvVOKU2Meta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: preview4hIvo0Ho9lMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeyMuAfyxw1dMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/compose.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: index5LgjsloLL8Meta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/emails/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: eventsuGwjm8lSURMeta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsuGwjm8lSURMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editB3VT5TIoxnMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsaN55tDxBETMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/[id]/registrations.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: index5LlkPctD9aMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/events/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesbkbzFByYsCMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/files.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: gallery8R0zIpTGtkMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/gallery.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsuZzA6yNCVsMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/group-details.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexwBJFOQFm8eMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersk6ZNFKDckUMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/members.vue").then(m => m.default || m)
  },
  {
    name: newsaxbxapA6deMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsaxbxapA6deMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editqhKd8lDvBbMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexXZPkgfahKuMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/admin/groups/[slug]/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src3037655557/src/pages/donate/[slug].vue").then(m => m.default || m)
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src3037655557/src/pages/donate/index.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src3037655557/src/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src3037655557/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src3037655557/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935IC1wfX89yMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/[slug]/files.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/[slug]/gallery.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/[slug]/shop.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src3037655557/src/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3037655557/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src3037655557/src/pages/join.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src3037655557/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src3037655557/src/pages/membership/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "membership",
    path: "/membership",
    meta: index5jnAImKIUHMeta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src3037655557/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src3037655557/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src3037655557/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452fasMLjzZudL0Meta || {},
    component: () => import("/codebuild/output/src3037655557/src/pages/setup-2fa.vue").then(m => m.default || m)
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src3037655557/src/pages/shop.vue").then(m => m.default || m)
  }
]